// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FlexpaApiErrors {
  export class NotFoundError extends Error {}
  export class UnauthorizedError extends Error {}
  export class ForbiddenError extends Error {}
  export class ConflictError extends Error {}
}

export type Role = 'ADMIN' | 'MEMBER';

export interface User {
  id: string;
  email: string;
  workOsId: string;
  createdAt: string;
  firstName?: string;
  lastName?: string;
  membership?: {
    id: string;
    role: Role;
  };
}

export function isUser(userOrInvite: User | UserInvite): userOrInvite is User {
  return (userOrInvite as User).workOsId !== undefined;
}

export interface UserInvite {
  id: string;
  email: string;
  workspaceId: string;
  createdAt: string;
  expiresAt: string;
}

export interface Application {
  id: string;
  createdAt: string;
  consentToDataAccess: boolean;
  publishableKeyTest: string;
  secretKeyTest?: string;
  publishableKeyLive?: string | null;
  secretKeyLive?: string | null;
}

export interface Workspace {
  id: string;
  name: string;
  workOsOrgId: string;
  createdAt: string;
  testModeEnabled: boolean;
  liveModeEnabled: boolean;
  applicationId: string | null;
  isBillingSet: boolean;
  serviceAgreementUrl: string | null;
}

export type LMAFStatus = 'IN_REVIEW' | 'APPROVED' | 'REJECTED';

export interface LiveModeAccessForm {
  id: string;
  workspaceId: string;
  submitterId: string;
  createdAt: string;
  updatedAt: string;
  submittedAt: string;

  country: string;
  postalCode: string;
  state: string;
  city: string;
  addressLine1: string;
  addressLine2: string;

  supportContactEmail: string;
  technicalContactEmail: string;
  technicalContactPhone: string;

  applicationDescription: string;
  websiteURL: string;
  privacyPolicyURL: string;
  termsOfServiceURL: string;
  taxIdNumber: string;

  sellsData:
    | 'NO'
    | 'USER_CONSENTS_AND_DATA_IS_NOT_DEIDENTIFIED'
    | 'USER_CONSENTS_AND_DATA_IS_DEIDENTIFIED'
    | 'NO_CONSENT_AND_DATA_IS_NOT_DEIDENTIFIED'
    | 'NO_CONSENT_AND_DATA_IS_DEIDENTIFIED';
  obtainCompleteRecord: boolean;
  conformToONCModelPrivacy: boolean;
  deleteUserDataUponRequest: boolean;
  deleteUserDataUponAccountClosure: boolean;
  hasThirdPartyVendors: boolean;
  thirdPartyDataProtection: boolean;
  directToBeneficiaryMarketing: boolean;

  status: LMAFStatus;
  reviewer: string;
  notes: string;

  pricingTier: string;
  expectedNumberOfUsers: number;
  guidanceDescription: string;
  supportDescription: string;
  programmingLanguage: string;
  platform: string;
}

export interface StripeBillingSession {
  url: string;
}

const BASE_URL = process.env['NEXT_PUBLIC_PORTAL_BASE_URL'];

interface ReqParams {
  cookie?: string | undefined;
}

export async function getWorkspace(reqParams?: ReqParams): Promise<Workspace> {
  return makeRequest(`${BASE_URL}/api/portal/workspaces`, {
    ...(reqParams?.cookie && { headers: { cookie: reqParams.cookie } }),
  });
}

export async function getUser(userId: string, reqParams?: ReqParams): Promise<User> {
  return makeRequest(`${BASE_URL}/api/portal/users/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      ...(reqParams?.cookie && { cookie: reqParams.cookie }),
    },
  });
}
async function makeRequest(url: string, reqInit: RequestInit) {
  const resp = await fetch(url, reqInit);

  if (resp.status === 204) {
    return;
  }

  if (!resp.headers.get('content-type')?.match(/application\/json/i)) {
    throw new Error('An unexpected error occured, please try again later.');
  }

  const jsonBody = await resp.json();

  if (!resp.ok) {
    switch (resp.status) {
      case 401:
        throw new FlexpaApiErrors.UnauthorizedError(jsonBody.message);
      case 404:
        throw new FlexpaApiErrors.NotFoundError(jsonBody.message);
      case 403:
        throw new FlexpaApiErrors.ForbiddenError(jsonBody.message);
      case 409:
        throw new FlexpaApiErrors.ConflictError(jsonBody.message);
      default:
        throw new Error(jsonBody.message ?? 'An unexpected error occurred, please try again later.');
    }
  }

  return jsonBody;
}
