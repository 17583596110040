import { Button, TextFormField } from '../designSystem';
import React from 'react';

interface FormData {
  formStatus: 'invalid' | 'valid' | 'submitting';
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  error?: Error | null | undefined;
}

interface ReadAndEditToggableFormProps {
  label: string;
  mode: 'read' | 'edit';
  onModeToggle: () => void;
  formData: FormData;
}

const ReadAndEditToggableForm = ({ label, mode, onModeToggle, formData }: ReadAndEditToggableFormProps) => {
  return (
    <div>
      <form
        className="flex w-56 flex-col"
        onSubmit={async (e) => {
          e.preventDefault();
          formData.onSubmit();
        }}
      >
        <TextFormField
          label={label}
          onChange={(e) => {
            formData.onChange(e.currentTarget.value);
            if (mode === 'read') {
              onModeToggle();
            }
          }}
          value={formData.value}
          error={formData.error?.message}
        />
      </form>

      <div className="flex h-[3.5rem]">
        {mode === 'edit' && <Button disabled={formData.formStatus !== 'valid'}>Save</Button>}
        {mode === 'edit' && (
          <div className="ml-2 mt-2">
            <Button variant="text" onClick={onModeToggle}>
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReadAndEditToggableForm;
